import * as React from "react"
import { ContactStyles } from "./ContactStyles"

const Contact = () => {
  return (
    <ContactStyles>
      <div className="column">
        <form name="contact" method="post" action="https://formspree.io/f/mleayqqy">
          <input placeholder="Your name" type="text" name="name" />
          <input placeholder="Your email" type="email" name="_replyto" />
          <textarea placeholder="Your message" name="message" rows="5"></textarea>
          <button className="btn" type="submit">
            Send Message
          </button>
        </form>
      </div>
      <div className="column">
        <h3>Our office</h3>
       <p>
        45 Douglas Street<br />
        Milton Queensland 4064<br />
        Australia</p>
        <p>Ph: +61 7 3367 2518</p>
        <p><a href="mailto:info@thinkingcap.com.au">info@thinkingcap.com.au</a></p>
        
     
      </div>
    </ContactStyles>
  )
}

export default Contact
